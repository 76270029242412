import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './styles/BackgroundWrapper.css';

const BackgroundWrapper = ({ children }) => {
    const location = useLocation();

    // Function to determine background color based on the route
    const getBackgroundClass = () => {
        switch (location.pathname) {
            case '/':
                return 'background-splash';
            case '/home':
                return 'background-white';
            case '/training':
                return 'background-white';
            case '/plans':
                return 'background-white';
            case '/profile':
                return 'background-white';
            case '/dashboard':
                return 'background-dashboard';
            case '/gpdashboard':
                return 'background-gpdashboard';
            case '/about':
                return 'background-white';
            case '/blog':
                return 'background-white';
            case '/workoutbuilder':
                return 'background-white';
            case '/story':
                return 'background-white';
            default:
                return 'background-white';
        }
    };

    return (
        <div className={classNames('background-wrapper', getBackgroundClass())}>
            {children}
        </div>
    );
};

export default BackgroundWrapper;
