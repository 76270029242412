import { Alert, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import * as Api from "../network/api";
import { SignUpCredentials } from "../network/api";
import { useForm } from "react-hook-form";
import { User } from "../models/user";
import '../styles/HomePage.css';
import colorPalette from "../utils/colors";

interface SignUpModalProps {
    onSignUpSuccessful: (user: User) => void,
    onLoginSuccessful: (user: User) => void,
}

const HomePageLoggedOutView = ({ onSignUpSuccessful, onLoginSuccessful }: SignUpModalProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    const [emailSent, setEmailSent] = useState(false); // New state to track email verification
    const { register, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm<SignUpCredentials>();

    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(true);

    const [isFadingOut, setIsFadingOut] = useState(false);
    const [isFadingIn, setIsFadingIn] = useState(false);

    const [GPAccountChecked, setGPAccountChecked] = useState(false);

    async function onSubmit(credentials: any) {
        if (showLoginModal) {
            // LOGIN
            try {
                const user = await Api.login(credentials);
                onLoginSuccessful(user);
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    setErrorText(error.message);
                } else {
                    setErrorText("An unexpected error occurred during login.");
                }
            }
        } else {
            // SIGN UP
            try {
                credentials.accountType = 'unset';
                credentials.GPAccount = GPAccountChecked;
                const newUser = await Api.signUp(credentials);
                onSignUpSuccessful(newUser)
                setErrorText(null);
            } catch (error) {
                if (error instanceof ConflictError) {
                    setErrorText(error.message);
                } else {
                    setErrorText("An unexpected error occurred during sign up.");
                }
            }
        }
    }

    const handleSignUpClick = () => {
        setIsFadingOut(true);
        setIsFadingIn(false);
        setTimeout(() => {
            setShowSignUpModal(true);
        }, 500); 
    };

    const handleLoginClick = () => {
        setIsFadingOut(true);
        setIsFadingIn(false);
        setTimeout(() => {
            setShowLoginModal(true);
        }, 500); 
    };

    const handleCancelClick = () => {
        setShowSignUpModal(false);
        setShowLoginModal(false);
        setIsFadingIn(true);
        setIsFadingOut(false);
        setTimeout(() => {
            setIsFadingIn(false);
        }, 500);
    };

    return (
        <div className="home-page" style={{ display: 'flex', height: '80vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {!showLoginModal && !showSignUpModal && 
            <div className={`main-content ${isFadingOut ? 'fade-out' : ''} ${isFadingIn ? 'fade-in' : ''} ${showSignUpModal ? 'hidden' : ''}`}>
                <h2 style={{ fontSize: '3.5em', color: 'white' }} className="main-title">Unleash Your Potential</h2>
                <p style={{ color: 'white' }}>Train smart with our <b>Adaptive Training</b> technology</p>
                <button className="signup-button" style={{ borderRadius: '0px' }} onClick={handleSignUpClick}>Sign Up Free</button>
                <button className="signup-button" style={{ borderRadius: '0px' }} onClick={handleLoginClick}>Login</button>
            </div>
            }

            {showSignUpModal && (
                <div className="modal-overlay">
                    <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff' }}>
                        <Form onSubmit={handleSubmit(onSubmit)} className="signup-form">
                            <h3 style={{ fontSize: '2.5vw', fontWeight: 'bold', color: colorPalette.steelBlue, fontStyle: 'italic' }}>JOIN US</h3>
                            {errorText && <Alert variant="danger">{errorText}</Alert>}
                            {emailSent && <Alert variant="success">A verification email has been sent to your email address. Please verify your email before logging in.</Alert>}
                            <Form.Group controlId="formBasicFirstname">
                                <Form.Label style={{ color: '#454545' }}>Full Name</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Full Name" 
                                    {...register("fullname", { required: "Full name is required" })} 
                                    className="user-form-input"
                                />
                                {errors.fullname && <Form.Text className="text-danger">{errors.fullname.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ color: '#454545' }}>Email Address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    {...register("email", { required: "Email is required" })} 
                                    className="user-form-input"
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                            </Form.Group>
                            
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ color: '#454545' }}>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    {...register("password", { required: "Password is required" })} 
                                    className="user-form-input"
                                />
                                {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label style={{ color: '#454545' }}>Confirm Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Confirm Password" 
                                    {...register("confirmPassword", {
                                        required: "Confirm Password is required",
                                        validate: (value) =>
                                            value === watch("password") || "Passwords do not match",
                                    })}
                                    className="user-form-input"
                                />
                                {errors.confirmPassword && <Form.Text className="text-danger">{errors.confirmPassword.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group style={{ flexDirection: 'row', display: 'flex', marginTop: '10px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <Form.Check 
                                    style={{ marginRight: '15px', marginBottom: '10px' }}
                                    type="checkbox" 
                                    checked={GPAccountChecked}
                                    onChange={() => setGPAccountChecked(!GPAccountChecked)}
                                    className="custom-checkbox" 
                                />
                                <Form.Label style={{ color: colorPalette.steelBlue, fontStyle: 'italic', fontWeight: 'bold' }}>I'M A GP</Form.Label>
                            </Form.Group>

                            <div className="button-group">
                                <Button variant="primary" type="submit" style={{ borderRadius: '0px', width: '100%', backgroundColor: colorPalette.steelBlue }} disabled={isSubmitting} className="submit-button">Sign Up</Button>
                            </div>

                            <label className="label-link" onClick={() => { setShowSignUpModal(false); setShowLoginModal(true); }} style={{ color: '#454545' }}>Already Have An Account?</label>
                        </Form>
                    </div>
                </div>
            )}

            {showLoginModal && (
                <div className="modal-overlay">
                    <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff' }}>
                        <Form onSubmit={handleSubmit(onSubmit)} className="signup-form" style={{ borderRadius: '0px' }}>
                            <h3 style={{ fontSize: '2.5vw', fontWeight: 'bold', color: colorPalette.steelBlue, fontStyle: 'italic' }}>LOGIN</h3>
                            {errorText && <Alert variant="danger">{errorText}</Alert>}
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ color: '#454545' }}>Email Address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    {...register("email", { required: "Email is required" })} 
                                    className="user-form-input"
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ color: '#454545' }}>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    {...register("password", { required: "Password is required" })} 
                                    className="user-form-input"
                                />
                                {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
                            </Form.Group>
                            <div className="button-group">
                                <Button variant="primary" type="submit" style={{ borderRadius: '0px', width: '100%', backgroundColor: colorPalette.steelBlue }} disabled={isSubmitting} className="submit-button">Login</Button>
                            </div>
                            <label className="label-link" onClick={() => { setShowSignUpModal(true); setShowLoginModal(false); }} style={{ color: '#454545' }}>Don't Have An Account?</label>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomePageLoggedOutView;
