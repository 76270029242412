import { Container } from "react-bootstrap";
import { User } from "../models/user";
import LoggedOutView from "../components/LoggedOutView";
import PreferencesPageLoggedInView from "../components/PreferencesPageLoggedInView";
import ProfilePageLoggedOutView from "../components/ProfilePageLoggedOutView";

interface PreferencesPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const PreferencesPage = ({ loggedInUser, updateUser }: PreferencesPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <PreferencesPageLoggedInView 
                        user={loggedInUser}
                        />
                    : <ProfilePageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default PreferencesPage;
