import { Container } from "react-bootstrap";
import { User } from "../models/user";
import ProfilePageLoggedOutView from "../components/ProfilePageLoggedOutView";
import OtherUserProfilePageLoggedInView from "../components/OtherUserProfilePageLoggedInView";

interface OtherUserProfilePageProps {
    loggedInUser: User | null,
}

const OtherUserProfilePage = ({ loggedInUser }: OtherUserProfilePageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <OtherUserProfilePageLoggedInView 
                        user={loggedInUser}
                        />
                    : <ProfilePageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default OtherUserProfilePage;
