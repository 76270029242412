import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { verifyEmail } from "../network/api";
import colorPalette from "../utils/colors";
import { Button } from "react-bootstrap";

const VerifyEmail: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState<"loading" | "success" | "error">("loading");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const verifyEmailHandler = async () => {
            const token = searchParams.get("token");
            const userId = searchParams.get("userId");
            console.log(token)

            if (!token || !userId) {
                setStatus("error");
                setMessage("Invalid verification link.");
                return;
            }

            try {
                // Sending token and userId as part of the body object
                const verifyEmailBody = { token, userId };
                const { message } = await verifyEmail(verifyEmailBody); // Call the updated verifyEmail function
                setStatus("success");
                setMessage(message || "Your userId has been successfully verified!");
            } catch (error:any) {
                setStatus("error");
                setMessage(error.message || "Something went wrong. Please try again.");
            }
        };

        verifyEmailHandler();
    }, [searchParams]);

    return (
        <div style={{ padding: "2rem", textAlign: "center" }}>
            {status === "loading" && <p>Verifying your email...</p>}
            {status === "success" && (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '70vh', // Ensure the container takes the full height of the viewport
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center', // Ensures the text is centered within the container
                    padding: '20px', // Optional, adds some spacing around the content
                }}>
                    <h1 style={{
                        color: colorPalette.steelBlue,
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        marginBottom: '20px', // Adds space between the heading and the message
                    }}>
                        EMAIL VERIFIED SUCCESSFULLY!
                    </h1>
                    
                    <p style={{
                        marginBottom: '20px', // Adds space between the message and the button
                        fontSize: '1.2em', // Makes the message slightly larger for readability
                        color: '#454545', // Neutral color for the text
                    }}>
                        {message}
                    </p>

                    <a href="/home" className="review-button" style={{
                        textDecoration: 'none', // Removes default underline from the anchor
                        width: 'auto', // Adjust width to fit the button content
                        display: 'flex',
                        justifyContent: 'center', // Center the button horizontally
                        alignItems: 'center', // Center the button vertically
                        padding: '10px',
                        marginTop: '0px', // Adds space between the message and the button
                        border:'none',
                        outline:'none',
                        backgroundColor:'transparent'
                    }}>
                        <Button style={{
                            backgroundColor: colorPalette.steelBlue,
                            fontStyle:'italic',
                            fontWeight:'bolder',
                            border: 'none',
                            color: 'white', // Ensure text is visible on the steelblue background
                            padding: '8px 12px', // Add some padding to make the button more prominent
                            fontSize: '1em', // Slightly larger font size for the button text
                            borderRadius: '5px', // Rounded corners for a modern look
                            cursor: 'pointer', // Makes it clear it's clickable
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Adds subtle shadow for a 3D effect
                            transition: 'background-color 0.3s', // Smooth background color transition on hover
                        }}>
                            CONTINUE TO VITOVA
                        </Button>
                    </a>
                </div>

            )}
            {status === "error" && (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '70vh', // Ensure the container takes the full height of the viewport
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center', // Ensures the text is centered within the container
                    padding: '20px', // Optional, adds some spacing around the content
                }}>
                    <h1 style={{
                        color: colorPalette.steelBlue,
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        marginBottom: '20px', // Adds space between the heading and the message
                    }}>
                        ERROR
                    </h1>
                    
                    <p style={{
                        marginBottom: '20px', // Adds space between the message and the button
                        fontSize: '1.2em', // Makes the message slightly larger for readability
                        color: '#454545', // Neutral color for the text
                    }}>
                        {message}
                    </p>

                    <a href="/home" className="review-button" style={{
                        textDecoration: 'none', // Removes default underline from the anchor
                        width: 'auto', // Adjust width to fit the button content
                        display: 'flex',
                        justifyContent: 'center', // Center the button horizontally
                        alignItems: 'center', // Center the button vertically
                        padding: '10px',
                        marginTop: '0px', // Adds space between the message and the button
                        border:'none',
                        outline:'none',
                        backgroundColor:'transparent'
                    }}>
                        <Button style={{
                            backgroundColor: colorPalette.steelBlue,
                            fontStyle:'italic',
                            fontWeight:'bolder',
                            border: 'none',
                            color: 'white', // Ensure text is visible on the steelblue background
                            padding: '8px 12px', // Add some padding to make the button more prominent
                            fontSize: '1em', // Slightly larger font size for the button text
                            borderRadius: '5px', // Rounded corners for a modern look
                            cursor: 'pointer', // Makes it clear it's clickable
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Adds subtle shadow for a 3D effect
                            transition: 'background-color 0.3s', // Smooth background color transition on hover
                        }}>
                            RETURN TO VITOVA
                        </Button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;
