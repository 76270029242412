import { ChecklistDataObject, DataObject, User } from "../models/user";
import colorPalette from "./colors";

function hasSubmittedSmokingDataToday(patient:User){
    const today = new Date();
    const isValueForToday = patient.smokingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
    });

    if (!isValueForToday) {
        return false;
    } else {
        return true;
    }
}

function hasSubmittedDrinkingDataToday (patient:User){
    const today = new Date();
    const isValueForToday = patient.drinkingData.some(data => {
    const dataDate = new Date(data.date);
    return (
        dataDate.getFullYear() === today.getFullYear() &&
        dataDate.getMonth() === today.getMonth() &&
        dataDate.getDate() === today.getDate()
    );
    });

    if (!isValueForToday) {
        return false;
    } else {
        return true;
    }

}
export function getSleepQualityColor(patient:User, value?: number): string {
    // Calculate the patient's age
    const dob = new Date(patient.info.dob);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear() - (today < new Date(today.getFullYear(), dob.getMonth(), dob.getDate()) ? 1 : 0);

    // Get the most recent sleep data
    const sleepHours = value ? value : patient.sleepingData.at(-1)?.value
    if (sleepHours === undefined || sleepHours === -1){
        // NOT SUBMITTED
        return '#34343466'
    }
    
    // Determine the age group and return the corresponding color
    if (age >= 65) {
        if (sleepHours >= 7) return colorPalette.seaGreen;
        else if (sleepHours >= 6 && sleepHours < 7) return colorPalette.orange;
        else if (sleepHours < 6) return colorPalette.softRed;
    } else if (age >= 18 && age <= 64) {
        if (sleepHours >= 7) return colorPalette.seaGreen;
        else if (sleepHours >= 6 && sleepHours < 7) return colorPalette.orange;
        else if (sleepHours < 6) return colorPalette.softRed;
    } else if (age >= 13 && age <= 17) {
        if (sleepHours >= 8) return colorPalette.seaGreen;
        else if (sleepHours >= 7 && sleepHours < 8) return colorPalette.orange;
        else if (sleepHours < 7) return colorPalette.softRed;
    } else if (age >= 6 && age <= 12) {
        if (sleepHours >= 9) return colorPalette.seaGreen;
        else if (sleepHours >= 8 && sleepHours < 9) return colorPalette.orange;
        else if (sleepHours < 8) return colorPalette.softRed;
    }
    // NOT SUBMITTED
    return '#34343466'
}

export function getAverageSleepQualityColor(patient: User) {
    if (patient.sleepingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.sleepingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid data points (values above or equal to 0)
        const count = patient.sleepingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;
        
        // Determine color based on the average sleep quality
        if (average >= 7) {
            return colorPalette.seaGreen; // Healthy sleep range
        } else if (average >= 6 && average < 7) {
            return colorPalette.orange; // Moderate sleep range
        } else {
            return colorPalette.softRed; // Poor sleep range
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}



export function getNutritionDataColor(patient:User, value?:ChecklistDataObject): string {
    const latestData = value ? value : patient.nutritionData.at(-1);

    if (latestData && latestData.value.length > 0) { // Check if there is data and its length is > 0
        const checkedCount = latestData.value.filter(v => v.checked === true).length; // Number of checked items
        
        // Mapping checkedCount to color
        if (checkedCount >= 0 && checkedCount <= 1) {
            return colorPalette.softRed;
        } else if (checkedCount > 1 && checkedCount <= 3) {
            return colorPalette.orange;
        } else {
            return colorPalette.seaGreen;
        }
    } else {
        // NOT SUBMITTED
        return '#34343466'
    }
}

export function getAverageNutritionColor(patient: User) {
    if (patient.nutritionData.length > 0) {
        // Calculate the total number of checked items
        const totalCheckedCount = patient.nutritionData.reduce((sum, o) => 
            sum + (o.value.filter(v => v.checked).length), 0);
        
        // Count the valid nutrition data points
        const count = patient.nutritionData.length;
        
        // Avoid division by zero if there are no valid data points
        const averageCheckedCount = count > 0 ? totalCheckedCount / count : 0;
        
        // Determine color based on the average number of checked items
        if (averageCheckedCount <= 1) {
            return colorPalette.softRed; // Low number of healthy items
        } else if (averageCheckedCount <= 3) {
            return colorPalette.orange; // Moderate number of healthy items
        } else {
            return colorPalette.seaGreen; // High number of healthy items
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}

export function getSmokingScoreColor(patient: User, value?: number) {
    if (patient.smoker) {
        if (hasSubmittedSmokingDataToday(patient)) {
            const today = new Date();
            const todaysSmokingValue = patient.smokingData.find(o => {
                const smokingDate = new Date(o.date); // Convert ISO 8601 string to Date object
                return smokingDate.getFullYear() === today.getFullYear() &&
                       smokingDate.getMonth() === today.getMonth() &&
                       smokingDate.getDate() === today.getDate();
            })?.value;

            // Use the provided value if available, otherwise fallback to today's smoking value
            const smokingValue = value !== undefined ? value : todaysSmokingValue || 0;
            if (smokingValue < 0){
                return '#34343466'
            }else if (smokingValue > 0) {
                // SUBMITTED RED
                return colorPalette.softRed;
            } else {
                // SUBMITTED GREEN
                return colorPalette.seaGreen;
            }
        } else {
            // NOT SUBMITTED
            return '#34343466';
        }
    } else {
        // NON SMOKER
        return '#34343422';
    }
}

export function getAverageSmokingScoreColor(patient: User) {
    if (patient.smokingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.smokingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid smoking data points
        const count = patient.smokingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;
        
        // Determine color based on the average smoking score
        if (average === 0) {
            return colorPalette.seaGreen; // Non-smoker or no smoking
        } else if (average > 0) {
            return colorPalette.softRed; // Smoker
        } else {
            return '#34343466'; // Not submitted
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}

export function getAlcoholScoreColor(patient: User, value?: number) {
    if (patient.drinker) {
        if (hasSubmittedDrinkingDataToday(patient)) {
            const today = new Date();
            const todaysDrinkingValue = patient.drinkingData.find(o => {
                const drinkingDate = new Date(o.date); // Convert ISO 8601 string to Date object
                return drinkingDate.getFullYear() === today.getFullYear() &&
                       drinkingDate.getMonth() === today.getMonth() &&
                       drinkingDate.getDate() === today.getDate();
            })?.value;

            // Use the provided value if available, otherwise fallback to today's drinking value
            const drinkingValue = value !== undefined ? value : todaysDrinkingValue || 0;
            if (drinkingValue < 0){
                return '#34343466'
            }else if (drinkingValue > 0) {
                // SUBMITTED RED
                return colorPalette.softRed;
            } else {
                // SUBMITTED GREEN
                return colorPalette.seaGreen;
            }
        } else {
            // NOT SUBMITTED
            return '#34343466';
        }
    } else {
        // NON SMOKER
        return '#34343422';
    }
}

export function getAverageAlcoholScoreColor(patient: User) {
    if (!patient.drinker){
        return '#34343422'
    }
    if (patient.drinkingData.length > 0) {
        // Calculate the total of positive values (ignore values below 0)
        const total = patient.drinkingData.reduce((sum, o) => sum + (Number(o.value) >= 0 ? Number(o.value) : 0), 0);

        // Count the number of valid (non-negative) data points
        const count = patient.drinkingData.filter(o => Number(o.value) >= 0).length;

        // Avoid division by zero if no valid values
        const average = count > 0 ? total / count : 0;

        // Determine color based on the average alcohol value
        if (average <= 2) {
            // SAFE RANGE (GREEN)
            return colorPalette.seaGreen;
        } else if (average > 2 && average <= 4) {
            // MODERATE RANGE (YELLOW or other available color)
            // Change to an existing color from colorPalette if softYellow is unavailable
            return colorPalette.gold;  // Example alternative
        } else {
            // HIGH RANGE (RED)
            return colorPalette.softRed;
        }
    } else {
        // Return a default color if the patient is not a drinker or has no data
        return '#34343466';
    }
}

