import { Activity, ActivityStep } from "../models/plan";
import { User } from "../models/user";
import { timeStringToSeconds } from "../utility";
import colorPalette from "./colors";
import getZones from "./Zones";

export function getTSS(step: ActivityStep, user:User){
    let totalTSS = 0;

    switch (step.stepType){
        case 'Run':
            if (step.stepDuration && step.stepDuration > 0) {
                // Convert duration from seconds to minutes
                const durationInMinutes = step.stepDuration / 60;
        
                let stepTSS = 0;
        
                const intensity = step.stepIntensity && step.stepIntensity > 0 
                ? step.stepIntensity 
                : step.stepZone && step.stepZone > 0 
                ? step.stepZone * 2  // Use a smaller multiplier, or a different calculation based on actual zone contribution
                : 1;
            
                // Estimate TSS based on RPE
                stepTSS = durationInMinutes * intensity
        
                // Add step TSS to total TSS
                totalTSS += stepTSS;
            }else if (step.stepDistance && step.stepDistance > 0) {
                if (user){
                    // Get Zones
                    const zones = getZones('Run', user);
        
                    // Get pace from intensity and zones
                    const pace = (timeStringToSeconds(zones[0].pace[0]) + timeStringToSeconds(zones[0].pace[1]))/2;
                    
                    const durationInMinutes = step.stepDistance / (pace / 60);
        
                    let stepTSS = 0;
        
                    const intensity = step.stepIntensity && step.stepIntensity > 0 ? step.stepIntensity : step.stepZone && step.stepZone > 0 ? step.stepZone * 2 : 1
        
                    // Estimate TSS based on RPE
                    stepTSS = durationInMinutes * intensity
        
                    // Add step TSS to total TSS
                    totalTSS += stepTSS;
                }
        
            }
            break;
        case 'Cycle':
            if (step.stepDistance && step.stepDistance > 0 && step.stepZone && step.stepZone > 0) {
                const cycleFTP = user.bestEfforts[4].avgPower; // User's FTP in Watts
                const userWeight = user.info.weight || 70; // Assume weight in kg, default to 70kg if not available
                
                // Get cycle zones data
                const cycleZones = getZones('Cycle', user);
                
                // Estimate average power for the step based on the zone
                const avgPower = (cycleZones[step.stepZone+1].power[0] + cycleZones[step.stepZone+1].power[1]) / 2;
                const stepPower = avgPower || 0; // Fallback to 0 if no power data available
                
                // Estimate average speed using a simplified power-to-speed model
                const estimatedSpeed = 9.5 * Math.pow(stepPower / userWeight, 1/3); // Speed in km/h
                
                // Estimate duration in hours and seconds
                const durationInHours = step.stepDistance / estimatedSpeed; // Duration in hours
                const durationInSeconds = durationInHours * 3600 / 4; // Duration in seconds
                
                // Intensity Factor (IF) is the ratio of step power to FTP
                // const intensityFactor = stepPower / cycleFTP;
                
                // Assuming NP is similar to average power in this simplified context
                const normalizedPower = stepPower; // Simplified assumption
                
                // Calculate TSS using the provided formula
                const stepTSS = (durationInSeconds * normalizedPower * step.stepZone) / (cycleFTP * 3600) * 100;
                
                // Add step TSS to total TSS
                totalTSS += stepTSS;
            }
            
            break;
        case 'Swim':
            if (user && step.stepDistance && step.stepDistance > 0 && step.stepZone && step.stepZone > 0) {
                // Get Zones for swimming instead of running
                const zones = getZones('Swim', user);
        
                // Get pace from intensity and zones for swimming (assuming it's in seconds per 100m or 100 yards)
                const pace = (timeStringToSeconds(zones[step.stepZone].pace[0]) + timeStringToSeconds(zones[step.stepZone].pace[1])) / 2;
                // Calculate duration in minutes based on swim distance and pace
                const durationInMinutes = ((step.stepDistance*1000) / 100) * (pace / 60);
                let stepTSS = 0;
        
                // Estimate intensity; higher zone corresponds to higher intensity
                const intensity = step.stepIntensity && step.stepIntensity > 0 
                                    ? step.stepIntensity 
                                    : step.stepZone && step.stepZone > 0 
                                    ? step.stepZone * 2 
                                    : 1;
        
                // Estimate TSS based on duration and intensity for swimming
                stepTSS = durationInMinutes * intensity;
        
                // Add step TSS to total TSS
                totalTSS += stepTSS;
            }
            break;
        default:
            if (step.stepReps && step.stepSets && step.stepReps > 0 && step.stepSets > 0){
                // Reps * average set time (1minute for example)
                const durationInMinutes = step.stepSets * 1;
        
                let stepTSS = 0;
        
                const intensity = step.stepIntensity && step.stepIntensity > 0 ? step.stepIntensity : step.stepZone && step.stepZone > 0 ? step.stepZone * 2 : 5
        
        
                stepTSS = durationInMinutes * intensity * 2;
        
                totalTSS += stepTSS;
            }
            break;
    }

    return Math.round(totalTSS * 1) / 1;
};

export function getSumTSS(activity:Activity, user:User){
    var sum = 0;
    for (const step of activity.steps){
        sum += getTSS(step, user)
    }
    return sum;
}

export function getIntensityColour(zone: number) {
    if (zone >= 0 && zone < 2) {
        return '#66CC66'; // Zone 1: Soft green
    } else if (zone >= 2 && zone < 3) {
        return '#99CC66'; // Zone 2: Muted yellow-green
    } else if (zone >= 3 && zone < 4) {
        return '#CCCC66'; // Zone 3: Soft yellow
    } else if (zone >= 4 && zone < 5) {
        return '#CC9966'; // Zone 4: Soft orange
    } else if (zone >= 5 && zone < 6) {
        return '#CC6666'; // Zone 5: Muted reddish-orange
    } else if (zone >= 6) {
        return '#CC3333'; // Zone 6: Soft red
    } else {
        throw new Error("Invalid zone. Zones should be >= 0.");
    }
}
