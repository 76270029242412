import * as Api from "../network/api";
import {ChecklistDataObject, DataObject, InboxMessage, User, UserTest } from '../models/user';
import profileImage from '../svg-icons/profile.svg';
import '../styles/GPDashboardPage.css';

import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faBeer, faBowlFood, faBullseye, faCheckCircle, faCircle, faCircleCheck, faCircleExclamation, faDotCircle, faExclamationCircle, faExternalLink, faInbox, faListCheck, faMessage, faMinus, faSmoking, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { getAlcoholScoreColor, getAverageAlcoholScoreColor, getAverageNutritionColor, getAverageSleepQualityColor, getAverageSmokingScoreColor, getNutritionDataColor, getSleepQualityColor, getSmokingScoreColor } from "../utils/GPFunctions";
import { Bar, Line } from "react-chartjs-2";
import { CheckBox } from "@mui/icons-material";

interface GPDashboardPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const GPDashboardPageLoggedInView = ({ user, onLogoutSuccessful }: GPDashboardPageLoggedInProps) => {
    const [patients, setPatients] = useState<Array<User>>([]);
    const [filteredPatients, setFilteredPatients] = useState<Array<User>>([]);

    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [updatingMessage, setUpdatingMessage] = useState('')

    const [expandedSections, setExpandedSections] = useState<{ [key: string]: string | null }>({});

    const toggleSection = (patientId: string, section: string) => {
        setExpandedSections((prev) => ({
            ...prev,
            [patientId]: prev[patientId] === section ? null : section, // Toggle the section for the specific patient
        }));
    };

    useEffect(() => {
        getMyPatients();
    }, []);

    const handleReadClicked = async (message: InboxMessage) => {
        try {
            setLoadingUpdate(true);

            if (!message._id) {
                throw new Error("Message ID is undefined.");
            }

            setUpdatingMessage(message._id)

    
            // Simulate or perform the API call to update the message
            const updatedMessage = { ...message, acknowledged: true }; // Example update
            const newMessage = await Api.updateMessage(user.gpReferralCode, message._id, updatedMessage);
    
            // Update the patients state immutably
            setPatients((prevPatients) =>
                prevPatients.map((patient) => {
                    if (patient._id === newMessage.senderId) {
                        // Replace the inbox array with an updated one
                        const updatedInbox = patient.inbox.map((msg) =>
                            msg._id === newMessage._id ? { ...msg, ...newMessage } : msg
                        );
                        return { ...patient, inbox: updatedInbox }; // Replace the patient object
                    }
                    return patient; // No changes for this patient
                })
            );
            // forceRerender();
            setLoadingUpdate(false);
        } catch (error) {
            console.error("Error updating message:", error);
            alert("There was an issue updating the message. Please try again later.");
            setLoadingUpdate(false);
        }
        setUpdatingMessage('');
    };

    const formatAsShortDayName = (date:Date) => {
        const dayLabel = new Intl.DateTimeFormat("en-GB", {
            weekday: "short"
        }).format(new Date(date))
        return dayLabel
    }
    function formatDateWithOrdinal(input: Date | string): string {
        let date: Date;
    
        // Ensure the input is a valid Date object
        if (input instanceof Date) {
            date = input;
        } else {
            date = new Date(input); // Attempt to convert string to Date
            if (isNaN(date.getTime())) {
                throw new TypeError("Invalid date input");
            }
        }
    
        const day = date.getDate();
    
        // Determine the appropriate ordinal suffix
        const getOrdinalSuffix = (n: number): string => {
            if (n % 10 === 1 && n % 100 !== 11) return "st";
            if (n % 10 === 2 && n % 100 !== 12) return "nd";
            if (n % 10 === 3 && n % 100 !== 13) return "rd";
            return "th";
        };
    
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${day}${ordinalSuffix}`;
    }
    
    async function getMyPatients(){
        const body:Api.GetPatientsBody = {gpReferralCode:user.gpReferralCode}
        const response = await Api.getPatients(body).then(res=>{
            setPatients(res.filter((p:User)=>p._id!==user._id));
        });
        setLoading(false);
        setFilteredPatients(patients)
        handleSearch('');
    }

    const handleSearch = (value: string = searchTerm) => {
        setSearchTerm(value);
        if (value.trim().length === 0){
            setFilteredPatients(patients);
        }else{
            setFilteredPatients(patients.filter(p=>p.fullname.toLowerCase().includes(value.toLowerCase())));
        }
    }

    const RenderMessagesDropdown = (patient:User) => {
        return (
            <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{`MESSAGES`}</label>
                        <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                        {patient.inbox.filter(m => m.senderId === patient._id).map((message) => (
                            <div style={{
                                display: 'flex',
                                padding: '5px 10px',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                border: '1px solid gray',
                                position: 'relative',
                                marginTop:'5px',
                                width: '100%',
                            }}>
                                <p style={{ margin: 0, padding: 0,fontSize: '0.75vw',marginBottom:'5px'}}>
                                    {message.dateSent.toString()}
                                </p>
                                <p style={{ margin: 0, padding: 0, fontWeight: 'bold' }} className="patient-name-label">{message.subject}</p>
                                <p style={{ margin: 0, padding: 0 }} className="patient-name-label">{message.content}</p>

                                {/* Acknowledge Button */}
                                <button
                                    disabled={loadingUpdate || message.acknowledged}
                                    style={{
                                        position: 'absolute',
                                        right: '0px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '0',
                                        fontSize: '2.5vw', // adjust size as needed
                                        color: 'green', // change color if needed
                                    }}
                                    // onClick={() => handleAcknowledge(message)} // Replace with your acknowledge logic
                                >
                                    <FontAwesomeIcon  onClick={() => handleReadClicked(message)} style={{color:patient.inbox.find(m=>m._id===message._id)?.acknowledged ? colorPalette.mediumSeaGreen:colorPalette.slateGray}} icon={updatingMessage === message._id ? faDotCircle : faCircleCheck} />
                                </button>
                            </div>
                        ))}
                </div>
            </div>
            </div>
            </div>

        )
    }
    const RenderAlcoholSection = (patient:User) => {
        return (
            <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{patient.drinker ? 'ALCOHOL TRACKER' : `${patient.fullname.split(' ')[0].toUpperCase()} DOESN'T DRINK`}</label>
                        {patient.drinker &&
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                <div style={{ height: '100%', display: 'flex', marginTop: '2vh' }}>
                                    {patient.drinkingData &&                                             
                                    <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                <Line 
                                                    data={{
                                                        labels: patient.drinkingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                label: `${patient.fullname.split(' ')[0]}'s Data`,
                                                                data: patient.drinkingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Makes the line dashed (5px dash and 5px gap)
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },
                                                            {
                                                                label: "Recommendeded Decrease (10% Every 4 Days)",
                                                                data: patient.drinkingData.map((o: DataObject, index: number) => {
                                                                    // Starting value, e.g., 100 (you can adjust this as needed)
                                                                    let initialValue = Number(patient.drinkingData[0].value);
                                                                    let decreasedValue = initialValue * Math.pow((1-0.026), index);
                                                                    return decreasedValue > 2 ? decreasedValue : 2;
                                                                }),
                                                                borderColor: `${colorPalette.seaGreen}`,
                                                                borderWidth: 2,
                                                                pointRadius: 0,
                                                                borderDash: [5, 5], // Dashed line
                                                                fill: false,
                                                                spanGaps: true,
                                                            },
                                                        ],
                                                    }}
                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: true, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '30px' }}
                                                />


                                    </div>

                                    }
                                </div>

                            </div>
                        }

                    </div>
                    </div>
                </div>
            </div>
        )
    }
    const RenderSleepSection = (patient:User) => {
        return (
            <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'SLEEP TRACKER'}</label>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                <div style={{ height: '100%', display: 'flex', marginTop: '2vh' }}>
                                    {patient.drinkingData &&                                             
                                    <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                            <Bar 
                                            data={{
                                                labels: patient.sleepingData.map((o:DataObject) =>
                                                    new Intl.DateTimeFormat("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "2-digit",
                                                    }).format(new Date(o.date))
                                                ),
                                                datasets: [
                                                    {
                                                        label: "Hours Slept",
                                                        data: patient.sleepingData.map((o: DataObject) => o.value), // Use only the values for the data points
                                                        backgroundColor: patient.sleepingData.map((o: DataObject) => getSleepQualityColor(patient, Number(o.value))),
                                                        borderColor: patient.sleepingData.map((o: DataObject) => getSleepQualityColor(patient, Number(o.value))),
                                                        borderWidth: 1,
                                                    },
                                                ],                                                
                                            }}
                                            options={{
                                                animation: {
                                                duration: 200,
                                                },
                                                scales: {
                                                y: {
                                                    type: 'linear', // specify the scale type
                                                    beginAtZero: true,
                                                    ticks:{
                                                    display:true
                                                    },
                                                    grid:{
                                                    display:false
                                                    }
                                                },
                                                x: {
                                                    ticks: {
                                                    display: true, // hide the x-axis ticks
                                                    },
                                                    grid:{
                                                    display:false
                                                    }
                                                },
                                                },
                                                maintainAspectRatio: false, // prevent the chart from maintaining aspect ratio
                                                responsive: true, // make the chart responsive
                                                plugins: {
                                                legend: {
                                                    display: false,
                                                    position: 'top', // adjust legend position as needed
                                                },
                                                },
                                                layout: {
                                                padding: {
                                                    top: 10,
                                                    bottom: 10,
                                                    left: 10,
                                                    right: 10,
                                                }
                                                }
                                            }}
                                            />


                                    </div>

                                    }
                                </div>

                            </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
    const RenderSmokingSection = (patient:User) => {
        return (
            <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{patient.smoker ? 'SMOKING TRACKER' : `${patient.fullname.split(' ')[0].toUpperCase()} DOESN'T SMOKE`}</label>
                        {patient.smoker &&
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                <div style={{ height: '100%', display: 'flex', marginTop: '2vh' }}>
                                    {patient.smokingData &&                                             
                                    <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                <Line 
                                                    data={{
                                                        labels: patient.smokingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                // label: "Your Data",
                                                                data: patient.smokingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Makes the line dashed (5px dash and 5px gap)
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },

                                                        ],
                                                    }}
                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: false, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '10px' }}
                                                />
                                    </div>

                                    }
                                </div>

                            </div>
                        }

                    </div>
                    </div>
                </div>
            </div>
        )
    }
    const RenderNutritionSections = (patient:User) => {
        return (
        <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                <div  style={{display:'flex', flexDirection:'row'}}>
                <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                    <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'NUTRITION TRACKER'}</label>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                            <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', width: '100%' }}>
                                    {/* Title for checklist score */}
                                    {/* <span style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>Checklist Score</span> */}

                                    {/* Table structure */}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {/* Header row: Days across the top */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                                            <div style={{ width: '40.5%', textAlign: 'left' }}></div> {/* Empty cell for labels */}
                                            {patient.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '50px', // Ensure alignment with circle widths
                                                        display: 'inline-block', // Prevent stretching
                                                    }}
                                                >
                                                    {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                </span>
                                            ))}
                                        </div>


                                        {/* Body rows: Labels on the left, circles in columns */}
                                        {patient.nutritionData[0]?.value.map((checkbox, labelIndex) => (
                                            <div key={labelIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                {/* Label */}
                                                <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>{checkbox.label}</div>

                                                {/* Circles for each day */}
                                                {patient.nutritionData.slice(-7).map((o: ChecklistDataObject, dayIndex) => {
                                                    const isChecked = o.value[labelIndex]?.checked;

                                                    return (
                                                        <div
                                                            key={dayIndex}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor:o.value.length <= 0 ? '#34343466' : (isChecked
                                                                    ? colorPalette.seaGreen
                                                                    : colorPalette.softRed),
                                                                marginLeft: '10px',
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ))}

                                        {/* Average row */}
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>Average</div>
                                            {patient.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => {
                                                const checkedCount = o.value.filter(v => v.checked).length;

                                                let backgroundColor = '';
                                                if (o.value.length <= 0){
                                                    backgroundColor = '#34343466';
                                                } else if (checkedCount > 3) {
                                                    backgroundColor = colorPalette.seaGreen;
                                                } else if (checkedCount > 1) {
                                                    backgroundColor = colorPalette.orange;
                                                } else {
                                                    backgroundColor = colorPalette.softRed;
                                                }

                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            backgroundColor: backgroundColor,
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        )
    }
    const RenderGoalsSections = (patient:User) => {
        return (
        <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                <div  style={{display:'flex', flexDirection:'row'}}>
                <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                    <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'GOALS TRACKER'}</label>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                            <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>
                            <div style={{width: '100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                <div
                                    style={{
                                    marginBottom: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    }}
                                >
                                    <table
                                    style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        textAlign: 'center',
                                    }}
                                    >
                                    <thead>
                                        <tr style={{ borderBottom: '2px solid #ccc' }}>
                                        <th>#</th>
                                        <th>Specific</th>
                                        <th>Measurable</th>
                                        <th>Achievable</th>
                                        <th>Relevant</th>
                                        <th>Time-Bound</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patient.goals &&
                                        patient.goals.map((goal, index) => (
                                            <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <label
                                                style={{
                                                    width: '90%',
                                                    display: 'block',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                }}
                                                >
                                                {goal.content.specific}
                                                </label>
                                            </td>
                                            <td>
                                                <label
                                                style={{
                                                    width: '90%',
                                                    display: 'block',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                }}
                                                >
                                                {goal.content.measurable}
                                                </label>
                                            </td>
                                            <td>
                                                <label
                                                style={{
                                                    width: '90%',
                                                    display: 'block',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                }}
                                                >
                                                {goal.content.achievable}
                                                </label>
                                            </td>
                                            <td>
                                                <label
                                                style={{
                                                    width: '90%',
                                                    display: 'block',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                }}
                                                >
                                                {goal.content.relevant}
                                                </label>
                                            </td>
                                            <td>
                                                <label
                                                style={{
                                                    width: '90%',
                                                    display: 'block',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                }}
                                                >
                                                {goal.content.timeBound}
                                                </label>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                </div>

                            </div>
                        </div>
                </div>
            </div>
        </div>
        )
    }
    const RenderTestsSections = (patient:User) => {
        return (
            <div className="expanded-content" style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                    <div  style={{display:'flex', flexDirection:'row'}}>
                        <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                            <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'TESTS TRACKER'}</label>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>
                                    <div style={{width: '100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                textAlign: 'center',
                                                marginTop:'2vh'
                                            }}
                                            >
                                            <thead style={{marginTop:'2vh'}}>
                                                <tr style={{ borderBottom: '2px solid #ccc' }}>
                                                <th>Test</th>
                                                <th>Type</th>
                                                <th>Number Of Questions</th>
                                                <th>Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {patient.tests.map((userTest: UserTest, index: number) => (
                                                <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>{userTest.test.name}</td>
                                                    <td>{userTest.test.type}</td>
                                                    <td>{userTest.test.numberOfQuestions}</td>
                                                    <td>{userTest.score.toString().replace('-1', 'Not Taken')}</td>
                                                </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
    function decimalToHexColor(value:number) {
        // Ensure the input is within the range [0, 1]
        value = Math.max(0, Math.min(1, value));
    
        // Calculate the red and green components
        const red = Math.round(255 * (1 - value)); // Red decreases as the value increases
        const green = Math.round(255 * value);    // Green increases as the value increases
    
        // Convert to hexadecimal format
        const redHex = red.toString(16).padStart(2, '0');
        const greenHex = green.toString(16).padStart(2, '0');
    
        // Blue is always 0 for this gradient
        const blueHex = '00';
    
        // Combine into a hex color string
        return `#${redHex}${greenHex}${blueHex}`;
    }

    return (
        loading ? (
            <div>
                <Spinner text="Loading Patients..." />
            </div>
        ) : (
            patients.length > 0 ? (
                <div 
                    className="dashboard-page" 
                    style={{ backgroundColor: '#ffffff', justifyContent: 'flex-start'}}
                >
                    <input
                            type="text"
                            placeholder="Search patients..."
                            value={searchTerm}
                            className="search-bar"
                            autoFocus
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                    <div style={{paddingBottom:'10vh'}}  className="patient-grid">
                        {(searchTerm.trim().length > 0 ? filteredPatients : patients).map((patient: User) => (
                            <div className="patient-card" key={patient._id}>
                                {/* Patient details */}
                                <div className="patient-details">
                                <div className="patient-column steel-blue">
                                        <label style={{fontWeight:'bold', fontStyle:'italic'}} className="patient-name-label">
                                            {patient.fullname.toUpperCase()}
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.accountType.replace('ffs', 'Fitness For Surgery').replace('gpr', 'GP Referral')}
                                        </label>
                                    </div>
                                    <div className="patient-column slate-gray">
                                        <label className="patient-name-label">
                                            {patient.currentPlan.title} Plan
                                        </label>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'inbox')} style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', position: 'relative' }} className="patient-column slate-gray">
                                    {/* Doctor icon */}
                                    <FontAwesomeIcon style={{ height: '20px', color: colorPalette.steelBlue}}icon={faInbox} />
                                    
                                    {patient.inbox
                                    .sort((m, n) => new Date(n.dateSent).getTime() - new Date(m.dateSent).getTime())
                                    .filter(m=>m.senderId===patient._id && m.acknowledged === false).length > 0 &&
                                        <FontAwesomeIcon 
                                        icon={faCircleExclamation} 
                                        style={{
                                            position: 'absolute', 
                                            color: colorPalette.softRed, 
                                            width: '15px', 
                                            height: '15px', 
                                            top: '10px', 
                                            right: '10px', 
                                            transform: 'translate(25%, -25%)' // Slight adjustment to position the exclamation inside the top-right corner
                                        }} 
                                    />
                                    }

                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'alcohol')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:getAverageAlcoholScoreColor(patient)}} icon={faBeer}/>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'smoking')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:getAverageSmokingScoreColor(patient)}} icon={faSmoking}/>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'sleep')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:getAverageSleepQualityColor(patient)}} icon={faBed}/>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'nutrition')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:getAverageNutritionColor(patient)}} icon={faBowlFood}/>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'goals')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:colorPalette.steelBlue}} icon={faBullseye}/>
                                    </div>
                                    <div onClick={() => toggleSection(patient._id, 'tests')} style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center', display:'flex'}} className="patient-column slate-gray">
                                        <FontAwesomeIcon style={{height:'20px',color:colorPalette.steelBlue}} icon={faListCheck}/>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                        className="patient-column slate-gray"
                                    >
                                    <FontAwesomeIcon
                                        style={{ height: '20px', color: colorPalette.steelBlue, cursor: 'pointer' }}
                                        icon={faExternalLink}
                                        onClick={() => {
                                            const newTab = window.open(
                                                `https://vitovaltd.com/profile/${patient._id}`,
                                                '_blank',
                                                'noopener,noreferrer'
                                            );
                                            if (newTab) {
                                                newTab.focus(); // Automatically switches to the newly opened tab
                                            }
                                        }}
/>

                                    </div>

                                </div>

                                {/* Expanded section */}
                                <div
                                    className={`expanded-section ${
                                        expandedSections[patient._id] ? 'expanded' : ''
                                    }`}
                                >
                                    {expandedSections[patient._id] === 'inbox' && RenderMessagesDropdown(patient)}
                                    {expandedSections[patient._id] === 'alcohol' && RenderAlcoholSection(patient)}
                                    {expandedSections[patient._id] === 'smoking' && RenderSmokingSection(patient)}
                                    {expandedSections[patient._id] === 'sleep' && RenderSleepSection(patient)}
                                    {expandedSections[patient._id] === 'nutrition' && RenderNutritionSections(patient)}
                                    {expandedSections[patient._id] === 'goals' && RenderGoalsSections(patient)}
                                    {expandedSections[patient._id] === 'tests' && RenderTestsSections(patient)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div 
                    className="dashboard-page" 
                    style={{ backgroundColor: '#f1f1f1' }}
                >
                    <label 
                        style={{ 
                            fontStyle: 'italic', 
                            fontWeight: 'bold', 
                            fontSize: '3vw' 
                        }}
                    >
                        Your Patients Will Appear Here
                    </label>
                    <label 
                        style={{ marginTop: '5px' }}
                    >
                        {`Your GP referral code is ${user.gpReferralCode}`}
                    </label>
                </div>
            )
        )
    )
}

export default GPDashboardPageLoggedInView;