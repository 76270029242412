import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Api from '../network/api';
import { StravaActivity, User } from "../models/user";

interface ConnectPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const ConnectPageLoggedInView = ({ user, onLogoutSuccessful }: ConnectPageLoggedInProps) => {
    const location = useLocation();
    const [isStravaConnected, setIsStravaConnected] = useState(false);
    const [stravaActivities, setStravaActivities] = useState<Array<StravaActivity>>([])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get("code");

        if (code) {
            const authorizeStrava = async () => {
                try {
                    // Authorize with Strava to get the access token
                    const authResponse = await Api.authorizeStrava({ code });
                    console.log("Authorization Response:", authResponse);

                    if (authResponse && authResponse.access_token) {
                        // Fetch activities using the access token
                        const activitiesResponse = await Api.fetchStravaActivities(authResponse.access_token);
                        
                        // Create a new user object and update stravaActivities
                        const newUser = { ...user, stravaActivities: activitiesResponse };
                    
                        // Update the user in the database
                        await Api.updateUser(user._id, newUser);
                    
                        // Log the activities and update the UI
                        console.log("Activities:", activitiesResponse);
                        setStravaActivities(activitiesResponse);
                        setIsStravaConnected(true);
                    } else {
                        console.error("No access token returned from Strava authorization.");
                    }
                    
                } catch (error) {
                    console.error("Error during Strava authorization or fetching activities:", error);
                }
            };

            authorizeStrava();
        } else {
            console.error("Authorization code is missing from the URL.");
        }
    }, [location.search]);

    return (
        <div className="profile-page">
            <div style={{ display: 'flex', width: '100%', height: 'auto' }}>
                <div style={{ display: 'flex', flex: 3, marginRight: '20px', width: '100%', height: 'auto', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <label style={{ color: '#565656', margin: '10px 10px', fontSize: '1.75vw', fontWeight: 'bold' }}>
                        {isStravaConnected ? "Connected To Strava" : "Connect To Strava"}
                    </label>
                    <div className="history-item" style={{ marginBottom: '2vh', height: '10vh', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                        <label style={{ color: '#565656', margin: '10px 10px', fontSize: '1.25vw', fontWeight: 'normal' }}>
                            {isStravaConnected ? "You are synced with Strava!" : "Sync with Strava to import your latest workouts!"}
                        </label>
                        {!isStravaConnected && (
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '8px 16px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    backgroundColor: '#fff',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#454545',
                                }}
                                onClick={() => window.location.href = `https://www.strava.com/oauth/authorize?client_id=132384&redirect_uri=http://localhost:3000/connect&response_type=code&scope=read,activity:read_all`}
                            >
                                Sync with <span style={{ color: '#fc4c02', fontStyle: 'italic', marginLeft: '5px' }}>STRAVA</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectPageLoggedInView;
