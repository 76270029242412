import * as Api from "../network/api";
import { CheckedCondition, CheckedMedication, Review, User } from '../models/user';
import '../styles/ProfilePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faEdit, faHeart, faHeartbeat, faLayerGroup, faLineChart, faList, faMedal, faPills} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { secondsToTimeString, timeStringToSeconds } from "../utility";
import colorPalette from "../utils/colors";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChartComponent from "./ChartComponent";
import { Day } from "../models/plan";
import getZones, { Zone } from "../utils/Zones";

interface ProfilePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const ProfilePageLoggedInView = ({user, onLogoutSuccessful}:  ProfilePageLoggedInProps) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const [inputValues, setInputValues] = useState<Array<string>>([]);
    const [hrInputValues, setHRInputValues] = useState<Array<number>>([]);

    const [myZones, setMyZones] = useState<Array<Zone> | null>(getZones('Run', user));
    
    const [currentActivityView, setCurrentActivityView] = useState('Run');

    const handleNextClick = () => {
        setCurrentActivityView(prevView => (prevView === 'Run' ? 'Cycle' : 'Run'));
    };

    useEffect(() => {
        const vals = user.bestEfforts.map(effort => effort.time !== 'n/a' ? effort.time : effort.avgPower.toString());
        setInputValues(vals);

        const hrVals = user.bestEfforts.map(effort => effort.avgHeartRate);
        setHRInputValues(hrVals);
    }, [user.bestEfforts]);

    // Function to handle selecting an activity
    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };

    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setSelectedOption(null);
    };

    async function handleSaveClicked() {
        try {
            setSaving(true)
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };
            
            // Update the new user's bestEfforts with input values
            for (let i = 0; i < inputValues.length; i++) {
                const paceValue = inputValues[i]
                if (newUser.bestEfforts[i].activityType === 'Run'){
                    const isValid = /^\d{2}:\d{2}:\d{2}$/.test(paceValue);
                    if (isValid) {
                        newUser.bestEfforts[i].time = paceValue
                        newUser.bestEfforts[i].avgPower = 0
                        newUser.bestEfforts[i].avgHeartRate = hrInputValues[i];
                    } else {
                        console.log('Please enter a time in the format hh:mm:ss')
                    }
                }else if (newUser.bestEfforts[i].activityType === 'Cycle'){
                    const isValid = Number(paceValue)
                    if (isValid) {
                        newUser.bestEfforts[i].avgPower = Number(paceValue)
                        newUser.bestEfforts[i].avgHeartRate = hrInputValues[i];
                    } else {
                        alert('Please enter a number')
                    }
                }else if (newUser.bestEfforts[i].activityType === 'Swim'){
                    const isValid = /^\d{2}:\d{2}:\d{2}$/.test(paceValue);
                    if (isValid) {
                        newUser.bestEfforts[i].time = paceValue
                        newUser.bestEfforts[i].avgPower = 0
                        newUser.bestEfforts[i].avgHeartRate = hrInputValues[i];
                    } else {
                        alert('Please enter a time in the format hh:mm:ss')
                    }
                }


            }
        
            // Update the user data via API
            await Api.updateUser(user._id, newUser);

            // Update Zones
            setMyZones(getZones('Run', user));
                
            // Update the state or perform any additional actions
            setSaving(false)
            setIsUpdating(false);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    // function getZones(){
    //     const bestEfforts = user.bestEfforts;
    //     const best5kEffort = bestEfforts.find(effort => effort.id === 0);

    //     if (best5kEffort){
    //         const FTP = timeStringToSeconds(best5kEffort.time) * 1.05
    //         const FTHR = best5kEffort.avgHeartRate * 0.93
    //         const distance = 5;


    //         var zones:Array<Zone> = [
    //             {
    //                 zone:'Zone 1',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 1.4)), secondsToTimeString(Math.round(FTP/distance * 1.29))],
    //                 hr:[Math.round(FTHR * 0.75),Math.round(FTHR * 0.85)]
    //             },
    //             {
    //                 zone:'Zone 2',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 1.29)), secondsToTimeString(Math.round(FTP/distance * 1.14))],
    //                 hr:[Math.round(FTHR * 0.85),Math.round(FTHR * 0.89)]                },
    //             {
    //                 zone:'Zone 3',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 1.14)), secondsToTimeString(Math.round(FTP/distance * 1.06))],
    //                 hr:[Math.round(FTHR * 0.89),Math.round(FTHR * 0.94)]                },
    //             {
    //                 zone:'Zone 4',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 1.06)), secondsToTimeString(Math.round(FTP/distance * 1.01))],
    //                 hr:[Math.round(FTHR * 0.94),Math.round(FTHR * 0.99)]                },
    //             {
    //                 zone:'Zone 5',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 1.01)), secondsToTimeString(Math.round(FTP/distance * 0.97))],
    //                 hr:[Math.round(FTHR * 0.99),Math.round(FTHR * 1.06)]                },
    //             {
    //                 zone:'Zone 6',
    //                 pace:[secondsToTimeString(Math.round(FTP/distance * 0.97)), secondsToTimeString(Math.round(FTP/distance * 0.9))],
    //                 hr:[Math.round(FTHR * 1.06), Math.round(FTHR * 1.2)]                },
    //         ]

    //         return zones.reverse();
    //     }else{
    //         return [];
    //     }
    // }

    function updateInputValues(id:number, input:string){
        var vals = inputValues;

        vals[id] = input

        setInputValues(vals);
    }

    function updateHRInputValues(id:number, input:string){
        var vals = hrInputValues;

        vals[id] = parseInt(input);

        setHRInputValues(vals);
    }

    function milliSecondsToDate(value: number): string {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()]; // Get the month name
        const year = date.getFullYear(); // Get the year
    
        return `${day} ${month} ${year}`;
    }

    const secondsToPaceString = (value: number): string => {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    function millisecondsToDateString(milliseconds:number) {
        const date = new Date(milliseconds);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
        const year = date.getFullYear();
        
        return `${day}/${month}/${year}`;
      }

    function getPastTrainingData(numberOfDays:number){
        var pastDays:Day[] = [];
        const reviews = user.reviews;
        for (const review of reviews){
            const dayToAdd:Day = {day:millisecondsToDateString(review.date),activities:[review.activity]}
            pastDays.push(dayToAdd)
        }
        return pastDays;
    }

    return (
        <div className="profile-page">
            <div style={{display:'flex', width:'100%', height:'auto'}}>

                <div style={{display:'flex', flex:3,marginRight:'20px',width:'100%', height:'auto', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                {/* <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.75vw', fontWeight:'bold'}}>Training Overview</label>
                {user.reviews.length > 0 ?
                    <ChartComponent bgc={'#fafafa'} weekData={getPastTrainingData(7).reverse()} user={user} mh={20}></ChartComponent>

                :
                <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                    <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Training Data Yet</label>
                </div>
                } */}
                <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.75vw', fontWeight:'bold'}}>Training History</label>
                {user.reviews.length <= 0 && (
                    <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                    <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Activity History Yet</label>
                </div>
                )}
                {user.reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((historyItem: Review, index: number) => (
                    historyItem.results.effort >= 0 ?
                    <div className="history-item" style={{marginBottom:'2vh', backgroundColor:index % 2 === 0 ? '#ffffff':'#fafafa'}}>
                        <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                            <div style={{display:'flex', flex:1}}>
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type}</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                <label style={{color:'#565656', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                            </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.duration}</label> 
                            </div>
                            <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{secondsToPaceString(historyItem.results.pace)}</label> 
                            </div>
                            <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.hr}</label> 
                            </div>
                            <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.effort} / 10</label> 
                            </div>
                            <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Sleep Quality</label>   
                                <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.sleepQuality} / 10</label> 
                            </div>

                        </div>
                    </div>
                    :                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:colorPalette.softRed}}>
                    <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                        <div style={{display:'flex', flex:1}}>
                            <label style={{color:'#f1f1f1', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type} (Missed)</label>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                            <label style={{color:'#f3f3f3', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                        </div>
                    </div>
                    <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                        </div>
                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                        </div>
                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                        </div>
                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                        </div>
                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Sleep Quality</label>   
                            <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                        </div>

                    </div>
                </div>
                ))}

                </div>
                <div style={{display:'flex', flex:2,width:'100%'}}>
                    <div className="side-container" style={{flexDirection:'column', justifyContent:'flex-start', alignItems:'center', paddingBottom:'15px'}}>
                        {/* Conditions */}
                        <div style={{backgroundColor:'white', height:'2px', width:'90%',marginTop:'20px', marginBottom:'20px'}}></div>
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faHeartbeat} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25vw',marginLeft:'10px', marginBottom:'10px', color:`#454545`, fontWeight:'bold'}}>Current Conditions</label>
                            </div>
                        </div>

                        <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-end', flexDirection:'row'}}>
                            <Link to={'/form'}>

                                <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} icon={faEdit}></FontAwesomeIcon>
                            </Link>
                            </div>
                            </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                            {user.info.conditions.map((condition:CheckedCondition)=>( condition.checked && 
                                <div style={{backgroundColor:`white`, width:'90%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                    <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                        <label style={{fontSize:'1vw', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>• {condition.condition.name}</label>
                                    </div>

                                </div>
                            ))}
                        </div>
                        {/* Medications */}
                        <div style={{backgroundColor:'white', height:'2px', width:'90%',marginTop:'20px', marginBottom:'20px'}}></div>
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faPills} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25vw',marginLeft:'10px', marginBottom:'10px', color:`#454545`, fontWeight:'bold'}}>Currently Taking Medications</label>
                            </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                            {user.info.medications.map((medication:CheckedMedication)=>( medication.checked && 
                                <div style={{backgroundColor:`white`, width:'90%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                    <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                        <label style={{fontSize:'1vw', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>• {medication.medication.name}</label>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div style={{ backgroundColor: 'white', height: '2px', width: '90%', margin: '20px auto' }}></div>

                        <div style={{ width: '100%'}}>
                        <div style={{width:'100%', height:'auto', backgroundColor:`ffffff33`, flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <div style={{width:'100%', height:'auto', backgroundColor:'transparent', flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                {user.activityPreferences.includes(0) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Run')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Run'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Run'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label style={{
                                            padding:'5px 20px', 
                                            fontSize:'1vw', 
                                            fontWeight:currentActivityView==='Run'?'bold':'normal',
                                            color:'#454545',
                                            pointerEvents:'none',
                                            }}>Run</label>
                                    </div>
                                }

                                {user.activityPreferences.includes(1) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Cycle')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Cycle'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Cycle'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label 
                                            style={{
                                                padding:'5px 20px', 
                                                fontSize:'1vw', 
                                                color:'#454545',
                                                fontWeight:currentActivityView==='Cycle'?'bold':'normal',
                                                pointerEvents:'none'
                                                }}>Cycle</label>
                                    </div>
                                }
                                
                                {user.activityPreferences.includes(2) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Swim')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Swim'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Swim'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label 
                                            style={{
                                                padding:'5px 20px', 
                                                fontSize:'1vw', 
                                                color:'#454545',
                                                fontWeight:currentActivityView==='Swim'?'bold':'normal',
                                                pointerEvents:'none'
                                                }}>Swim</label>
                                    </div>
                                }

                            </div>

                        </div>
                            {/* <div style={{ backgroundColor: 'white', height: '2px', width: '90%', margin: '20px auto' }}></div> */}

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop:'3vh'}}>
                                <FontAwesomeIcon icon={faLayerGroup} style={{ color: '#454545', width: '25px', height: '25px', marginLeft: '20px' }} />
                                <div style={{ display: 'flex', width: '100%', flex: 1, justifyContent: 'flex-start' }}>
                                    <label className="stats-label" style={{ fontSize: '1.25vw', marginLeft: '10px', marginBottom: '10px', color: '#454545', fontWeight: 'bold' }}>
                                        {currentActivityView === 'Run' ? 'Run Zones' : currentActivityView === 'Cycle'? 'Cycling Zones':'Swim Zones'}
                                    </label>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', height: 'auto', flexDirection: 'column', alignItems: 'center', textAlign: 'left' }}>
                                {currentActivityView === 'Run' ? (
                                    getZones('Run', user).map((zone) => (
                                        <div key={zone.zone} style={{ backgroundColor: 'white', width: '90%', margin: '8px 0px', borderRadius: '5px', flexDirection: 'row', display: 'flex' }}>
                                            <div style={{ display: 'flex', width: '100%', flex: 1, backgroundColor: '#d1d1d1' }}>
                                                <label style={{ fontSize: '1vw', color: '#454545', textAlign: 'left', padding: '5px 15px', fontWeight: 'bold' }}>{zone.zone}</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 2.5, backgroundColor: '#e1e1e1', justifyContent: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.pace[1]} - ${zone.pace[0]} min/km`}
                                                </label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 1.5, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.hr[1]} - ${zone.hr[0]} BPM`}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                ) : 
                                currentActivityView === 'Cycle' ?(
                                    getZones('Cycle', user).map((zone) => (
                                        <div key={zone.zone} style={{ backgroundColor: 'white', width: '90%', margin: '8px 0px', borderRadius: '5px', flexDirection: 'row', display: 'flex' }}>
                                            <div style={{ display: 'flex', width: '100%', flex: 1, backgroundColor: '#d1d1d1' }}>
                                                <label style={{ fontSize: '1vw', color: '#454545', textAlign: 'left', padding: '5px 15px', fontWeight: 'bold' }}>{zone.zone}</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 2.5, backgroundColor: '#e1e1e1', justifyContent: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.power[1]} - ${zone.power[0]} Watts`}
                                                </label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 1.5, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.hr[1]} - ${zone.hr[0]} BPM`}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                ):
                                getZones('Swim', user).map((zone) => (
                                    <div key={zone.zone} style={{ backgroundColor: 'white', width: '90%', margin: '8px 0px', borderRadius: '5px', flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ display: 'flex', width: '100%', flex: 1, backgroundColor: '#d1d1d1' }}>
                                            <label style={{ fontSize: '1vw', color: '#454545', textAlign: 'left', padding: '5px 15px', fontWeight: 'bold' }}>{zone.zone}</label>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', flex: 2.5, backgroundColor: '#e1e1e1', justifyContent: 'center' }}>
                                            <label
                                                style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                className="card-title"
                                            >
                                                {`${zone.pace[1]} - ${zone.pace[0]} min/100m`}
                                            </label>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', flex: 1.5, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center' }}>
                                            <label
                                                style={{ fontSize: '1vw', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                className="card-title"
                                            >
                                                {`${zone.hr[1]} - ${zone.hr[0]} BPM`}
                                            </label>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        {currentActivityView === 'Run' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'90%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25vw',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Run)</label>
                                </div>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-end'}}>
                                    {isUpdating ?
                                        saving ?
                                            <Spinner style={{marginRight:'25px', width:'20px', height:'20px', color:'steelblue'}} ></Spinner>
                                            :
                                            <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>handleSaveClicked()} icon={faCheck}></FontAwesomeIcon>

                                    :
                                        <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>setIsUpdating(true)} icon={faEdit}></FontAwesomeIcon>
                                    }
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {user.bestEfforts.filter(e=>e.activityType==='Run').map((effort)=>(
                                    <div style={{backgroundColor:`white`, width:'90%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:1}}>
                                            <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{effort.type}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                            <input
                                                type="text"
                                                pattern="\d{2}:\d{2}:\d{2}"
                                                maxLength={8}
                                                // value={inputValues[effort.id]}
                                                onChange={e => {
                                                    const value = e.currentTarget.value;
                                                    const isValid = /^\d{2}:\d{2}:\d{2}$/.test(value);
                                                    if (isValid) {
                                                        updateInputValues(effort.id, value.toString());
                                                    } else {
                                                        e.currentTarget.setCustomValidity("Please enter a valid time in the format nn:nn:nn");
                                                    }
                                                }}
                                                disabled={!isUpdating || saving}
                                                style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                defaultValue={inputValues[effort.id]} 
                                                className="card-title" 
                                            />
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                        )
                        }
                        {currentActivityView === 'Cycle' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'90%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25vw',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Cycle)</label>
                                </div>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-end'}}>
                                    {isUpdating ?
                                        saving ?
                                            <Spinner style={{marginRight:'25px', width:'20px', height:'20px', color:'steelblue'}} ></Spinner>
                                            :
                                            <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>handleSaveClicked()} icon={faCheck}></FontAwesomeIcon>

                                    :
                                        <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>setIsUpdating(true)} icon={faEdit}></FontAwesomeIcon>
                                    }
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {user.bestEfforts.filter(e=>e.activityType==='Cycle').map((effort)=>(
                                    <div style={{backgroundColor:`white`, width:'90%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:1}}>
                                            <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{effort.type}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                            <input
                                                type="text"
                                                maxLength={8}
                                                // value={inputValues[effort.id]}
                                                onChange={e => {
                                                    const value = e.currentTarget.value;
                                                    const isValid = Number(value);
                                                    if (isValid) {
                                                        updateInputValues(effort.id, value.toString());
                                                    } else {
                                                        e.currentTarget.setCustomValidity("Please enter a number");
                                                    }
                                                }}
                                                disabled={!isUpdating || saving}
                                                style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                defaultValue={inputValues[effort.id]} 
                                                className="card-title" 
                                            />
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                        )
                        }
                        {currentActivityView === 'Swim' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'90%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25vw',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Swim)</label>
                                </div>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-end'}}>
                                    {isUpdating ?
                                        saving ?
                                            <Spinner style={{marginRight:'25px', width:'20px', height:'20px', color:'steelblue'}} ></Spinner>
                                            :
                                            <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>handleSaveClicked()} icon={faCheck}></FontAwesomeIcon>

                                    :
                                        <FontAwesomeIcon className="edit-icon" style={{marginRight:'25px', width:'20px', height:'20px'}} onClick={()=>setIsUpdating(true)} icon={faEdit}></FontAwesomeIcon>
                                    }
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {user.bestEfforts.filter(e=>e.activityType==='Swim').map((effort)=>(
                                    <div style={{backgroundColor:`white`, width:'90%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:1}}>
                                            <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#e1e1e1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{effort.type}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                            <input
                                               type="text"
                                               pattern="\d{2}:\d{2}:\d{2}"
                                               maxLength={8}
                                               // value={inputValues[effort.id]}
                                               onChange={e => {
                                                   const value = e.currentTarget.value;
                                                   const isValid = /^\d{2}:\d{2}:\d{2}$/.test(value);
                                                   if (isValid) {
                                                       updateInputValues(effort.id, value.toString());
                                                   } else {
                                                       e.currentTarget.setCustomValidity("Please enter a valid time in the format nn:nn:nn");
                                                   }
                                               }}
                                                disabled={!isUpdating || saving}
                                                style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                defaultValue={inputValues[effort.id]} 
                                                className="card-title" 
                                            />
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                        )
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    );

}

export default ProfilePageLoggedInView;